import { QueryClient, QueryClientProvider } from "react-query";

import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types"; // Add this import
import "./styles.css";

import "./i18n";

export const AppContext = createContext();

export function App({ children }) {
	const queryClient = new QueryClient();

	const [transientTasks, setTransientTasks] = useState([]);
	const [transientAreas, setTransientAreas] = useState([]);
	const value = {
		transientTasks,
		setTransientTasks,
		transientAreas,
		setTransientAreas,
	};

	return (
		<AppContext.Provider value={value}>
			<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
		</AppContext.Provider>
	);
}

App.propTypes = {
	children: PropTypes.node.isRequired,
};

export function useAppContext() {
	return useContext(AppContext);
}
