import * as api from "../../utils/api";
import { useMutation } from "react-query";
import * as queryKeyFactory from "../../query_key_factory";
import { KeyAssigner } from "../../utils/key_assigner";
import {
	isTransient,
	createTransientPersistentLayer,
	PersistedKeyIdentifier,
} from "../../utils/transient_persistent_layer";

window.taskKeyAssigner = window.taskKeyAssigner || new KeyAssigner();
window.taskPersistedKeyIdentifier =
	window.taskPersistedKeyIdentifier ||
	new PersistedKeyIdentifier([
		"id",
		"description",
		"time_estimate",
		"priority",
		"due_date",
		"is_done",
		"is_deleted",
		"area_id",
		"title",
	]);

const taskComparator = (a, b) => {
	const getComparisonKey = (task) => {
		const position_override =
			task.transient?.position_override === "top"
				? 0
				: task.transient?.position_override === "bottom"
					? 2
					: 1;
		const id = typeof task.id === "number" ? task.id : task.id[1];
		return [
			task.priority === null ? Infinity : task.priority,
			position_override,
			// if position_override is top, that particular id-based sort is reversed
			position_override === 0 ? -id : id,
		];
	};

	const keyA = getComparisonKey(a);
	const keyB = getComparisonKey(b);

	for (let i = 0; i < keyA.length; i++) {
		if (keyA[i] < keyB[i]) return -1;
		if (keyA[i] > keyB[i]) return 1;
	}

	return 0;
};

const loadTransientTasks = () => {
	return [...(window.transientTasks || [])];
};

const saveTransientTasks = (tasks) => {
	window.transientTasks = [...tasks];
};

const { combinedLayer } = createTransientPersistentLayer({
	persistentQueryKey: queryKeyFactory.tasks.persistent(),
	transientQueryKey: queryKeyFactory.tasks.transient(),
	fetchItems: api.fetchTasks,
	createItem: (task) => {
		const result = api.createTask({
			...task,
			id: undefined,
			transient: undefined,
		});
		return result;
	},
	updateItem: (task) => {
		const result = api.saveTask({ ...task, transient: undefined });
		return result;
	},
	deleteItem: async ({ item: task, delete_state }) => {
		if (delete_state) {
			return await api.setTaskDelete(task.id, delete_state);
		} else {
			throw Error("delete_state must be true");
		}
	},
	setItemProperty: ({ item: task, property, value }) => {
		if (property === "is_done") {
			return api.setTaskDone(task.id, value);
		} else {
			return api.saveTask({
				...task,
				[property]: value,
				transient: undefined,
			});
		}
	},
	itemComparator: taskComparator,
	loadTransientItems: loadTransientTasks,
	saveTransientItems: saveTransientTasks,
	keyAssigner: window.taskKeyAssigner,
	persistedKeyIdentifier: window.taskPersistedKeyIdentifier,
});

export function tasks() {
	const {
		getData,
		createData,
		updateData,
		deleteData,
		setProperty,
		persistent,
		transient,
	} = combinedLayer();

	const createTransientData = useMutation(({ insert_at_bottom, area_id }) => {
		const newTask = {
			id: ["new", Date.now()],
			description: "",
			time_estimate: null,
			priority: null,
			due_date: null,
			is_done: false,
			is_deleted: false,
			area_id: area_id,
			title: "",
			transient: {
				immediate_focus_requested: true,
				position_override: insert_at_bottom ? "bottom" : "top",
			},
		};
		return createData.mutateAsync(newTask);
	});

	/*
	const updateArea = useMutation(async ({ newAreaId, tasks }) => {
		const updates = tasks.map((task) =>
			updateData.mutateAsync({ item: { ...task, area_id: newAreaId } }),
		);
		return Promise.all(updates);
	});
	*/
	const updateAreaPersistent = useMutation(
		({ targetAreaId, selectedTasks }) =>
			api.moveTasksToArea(targetAreaId, selectedTasks),
		{
			onMutate: ({ targetAreaId, selectedTasks }) => {
				// Optimistically update the tasks
				persistent.queryClient.setQueriesData(persistent.filter, (oldData) =>
					oldData.map((task) =>
						selectedTasks.includes(task.id)
							? { ...task, area_id: parseInt(targetAreaId) }
							: task,
					),
				);
			},
			onError: () => {
				// Refetch tasks only if the mutation fails
				persistent.queryClient.invalidateQueries(persistent.filter);
			},
		},
	);

	const updateArea = useMutation(async ({ newAreaId, tasks }) => {
		// split types of tasks by transient/persistent
		const transientTasks = tasks.filter((task) => isTransient(task.id));
		const persistentTasks = tasks.filter((task) => !isTransient(task.id));

		// Update transient tasks
		const transientUpdates = transientTasks.map((task) =>
			transient.updateData.mutateAsync({
				item: { ...task, area_id: newAreaId },
			}),
		);

		// Update persistent tasks
		const persistentUpdate =
			persistentTasks.length > 0
				? updateAreaPersistent.mutateAsync({
						targetAreaId: newAreaId,
						selectedTasks: persistentTasks.map((task) => task.id),
					})
				: Promise.resolve();

		// Wait for all updates to complete
		return Promise.all([...transientUpdates, persistentUpdate]);
	});

	return {
		getData,
		createTransientData,
		deleteData,
		updateData,
		updateArea,
		setDone: ({ task, done_state }) => {
			return setProperty.mutateAsync({
				item: task,
				property: "is_done",
				value: done_state,
			});
		},
	};
}

// TODO issue that requires backend modifications: If editing a persistent task, then a fetchTasks update hits due to timing, the edit will be lost to time. Probably needs to be in transient as well as persistent, with merge logic to pick the right one?
