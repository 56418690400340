import { React, useMemo } from "react";
import PropTypes from "prop-types";
import "./TaskField.css";
import { AreaType } from "../area/Area.type";

export default function TaskField({
	className,
	value,
	onInput,
	onBlur,
	error,
	area,
	isStriked,
	refCallback,
	onKeyDownProcessor,
	testId,
}) {
	const taskStyle = useMemo(() => {
		return {
			//position: 'relative',
			backgroundColor: area?.color || "transparent",
			textDecoration: isStriked ? "line-through" : undefined,
			color: isStriked ? "gray" : undefined,
			border: error ? "1px solid red" : undefined,
		};
	}, [area, isStriked, error]);

	return (
		<span
			data-testid={testId}
			style={taskStyle}
			className={`task-field ${className}`}
			contentEditable={true}
			suppressContentEditableWarning
			onInput={onInput}
			onBlur={onBlur}
			ref={refCallback}
			onKeyDown={onKeyDownProcessor}
		>
			{value}
		</span>
	);
}

TaskField.propTypes = {
	className: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onInput: PropTypes.func.isRequired,
	onBlur: PropTypes.func.isRequired,
	error: PropTypes.bool,
	area: AreaType.isRequired,
	isStriked: PropTypes.bool,
	refCallback: PropTypes.func,
	onKeyDownProcessor: PropTypes.func,
	testId: PropTypes.string,
};
