import PropTypes from "prop-types";

export const TaskPersistent = PropTypes.shape({
	// required things
	id: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.arrayOf(
			PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		),
	]).isRequired,
	title: PropTypes.string.isRequired,
	is_done: PropTypes.bool.isRequired,
	area_id: PropTypes.number.isRequired,

	// nullable things
	description: PropTypes.string,
	time_estimate: PropTypes.number,
	priority: PropTypes.number,
	due_date: PropTypes.string,
});

export const TaskTransient = PropTypes.shape({
	is_create_pending: PropTypes.bool,
	immediate_focus_requested: PropTypes.bool,
	key: PropTypes.string,
});

export const TaskType = PropTypes.shape({
	...TaskPersistent,

	// transient things, not database backed
	is_deleted: PropTypes.bool,
	transient: TaskTransient,
});
