import React, { StrictMode } from "react";
import { App } from "./App";
import { AreaList } from "./components/area/AreaList";
import { TaskList } from "./components/task/TaskList";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { GoogleLoginComponent } from "./components/Login";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
	<App>
		<StrictMode>
			<QueryClientProvider client={queryClient}>
				<GoogleOAuthProvider clientId={process.env.REACT_APP_GG_APP_ID}>
					<GoogleLoginComponent />

					<AreaList />
					<TaskList />
				</GoogleOAuthProvider>
			</QueryClientProvider>
		</StrictMode>
	</App>,
);
