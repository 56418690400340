import React, {
	useState,
	useEffect,
	useRef,
	useCallback,
	useMemo,
} from "react";
import PropTypes from "prop-types";
import { validate } from "../../utils/validation";
import TaskTitle from "./TaskTitle";
import TaskTimeEstimate from "./TaskTimeEstimate";
import TaskPriority from "./TaskPriority";
import TaskDueDate from "./TaskDueDate";
import "./Task.css";
import * as areaQuery from "../area/AreaList.query";
import { TaskType } from "./Task.type";
import * as query from "./TaskList.query";
import { isTransient } from "../../utils/transient_persistent_layer";

function Task({
	task,
	isEditing,
	isSelected,
	onSelect,
	keypressInTaskHandler,
}) {
	const [editedValues, setEditedValues] = useState({
		title: task.title,
		time_estimate: task.time_estimate,
		priority: task.priority,
		due_date: task.due_date,
	});
	const [errors, setErrors] = useState({
		title: false,
		time_estimate: false,
		priority: false,
		due_date: false,
	});
	const [hasChanged, setHasChanged] = useState(false);

	const titleRef = useRef(null);
	const hasFocusedRef = useRef(false);

	const { updateData, deleteData, setDone } = query.tasks();

	const { data: areas = [], isLoading: isLoadingAreas } =
		areaQuery.useAreasIncludingTransient();
	const area = useMemo(
		() => areas.find((area) => area.id === task.area_id),
		[areas, task.area_id],
	);

	useEffect(() => {
		if (isSelected) {
			onSelect();
			if (titleRef.current && !hasFocusedRef.current) {
				titleRef.current.focus();

				// TODO select all text, seems very difficult to do properly, because contentEditable is true?

				// if the Task isn't within the viewport, scroll it into view
				const taskElement = titleRef.current.closest(".task");
				if (taskElement) {
					taskElement.scrollIntoView({ behavior: "smooth", block: "center" });
				}

				hasFocusedRef.current = true;
			}
		} else {
			hasFocusedRef.current = false;
		}
	}, [isSelected, onSelect]);

	const titleRefCallback = useCallback(
		(node) => {
			titleRef.current = node;
			if (node && task.transient?.immediate_focus_requested) {
				node.focus();
				updateData.mutateAsync({
					item: {
						...task,
						transient: { ...task.transient, immediate_focus_requested: false },
					},
					skipPersist: true,
				});
			}
		},
		[task],
	);

	const handleInput = (field, event) => {
		const value = event.target.innerText.trim();
		const { isValid } = validate(field, value);

		if (isValid || field === "due_date" || field === "title") {
			setErrors({ ...errors, [field]: false });
			if (value !== editedValues[field]) {
				setHasChanged(true);
				//setEditedValues({ ...editedValues, [field]: value });
			}
		} else {
			event.target.innerText = editedValues[field];
			setErrors({ ...errors, [field]: true });
			event.target.classList.add("flash-error");
			setTimeout(() => {
				event.target.classList.remove("flash-error");
			}, 300);
		}
	};

	const handleBlur = (field, event) => {
		const value = event.target.innerText.trim();
		const { isValid } = validate(field, value);
		if (isValid) {
			setErrors({ ...errors, [field]: false });
			if (hasChanged) {
				editedValues[field] = value; // immediate update as well as notification update, because immediate save follows
				setEditedValues({ ...editedValues, [field]: value });

				// check if relatedTarget is our task-delete button
				if (event.relatedTarget?.classList?.contains("task-delete")) {
					// is the button contained inside our container?
					const container = event.target.closest(".task");
					if (container && container.contains(event.relatedTarget)) {
						// if so, do not save
						return;
					}
				}
				trySave();
			}
		} else {
			setErrors({ ...errors, [field]: true });
		}
	};

	const trySave = async () => {
		const validatedValues = {};
		let allValid = true;

		for (const [key, value] of Object.entries(editedValues)) {
			const { isValid, validatedValue } = validate(key, value);
			validatedValues[key] = validatedValue;
			allValid = allValid && isValid;
		}

		if (!allValid) {
			return;
		}

		// perform the save
		updateData.mutate({
			item: { ...task, ...validatedValues },
			skipPersist: false,
		});
	};

	const handleKeyDown = (event, field) => {
		keypressInTaskHandler(event, field, task);
		// TODO ctrl+enter allows editing description
		//if (event.ctrlKey && event.key === 'Enter') {
		// enter saves the task
		if (event.key === "Enter") {
			// TODO create test for enter
			if (document.activeElement) {
				document.activeElement.blur();
			}
			event.preventDefault();
			//trySave();
			// TODO if enter ended editing a new Task, immediately move on to editing another new task
		}

		// escape stops editing (and cancels the operation)
		if (event.key === "Escape") {
			event.preventDefault();
			if (field == "title" && isTransient(task)) {
				// delete the transient task
				deleteData.mutateAsync({ item: task });
			} else {
				// TODO abort and restore the original value
				if (document.activeElement) {
					document.activeElement.blur();
				}
			}
		}
	};

	useEffect(() => {
		if (isEditing) {
			document.addEventListener("keydown", handleKeyDown);
		} else {
			document.removeEventListener("keydown", handleKeyDown);
		}

		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [isEditing, editedValues]);

	useEffect(() => {
		if (isSelected) {
			onSelect();
		}
	}, [isSelected, onSelect]);

	if (isLoadingAreas) {
		return <div>Loading...</div>;
	}

	return (
		<div
			id={"task_" + task.id}
			className={`task ${isSelected ? "selected" : ""}`}
		>
			<span style={{ display: "none" }}>{JSON.stringify(task)}</span>
			<button
				data-testid="done-task-button"
				className="task-done"
				onClick={() => setDone({ task, done_state: !task.is_done })}
				style={{
					color: task.is_done ? "green" : "#d3d3d3",
					fontWeight: task.is_done ? "bold" : "normal",
					position: "relative",
					background: task.is_done ? "#ddffdd" : undefined,
				}}
			>
				✔
			</button>
			<TaskTitle
				titleCallback={titleRefCallback}
				style={{ "text-decoration": task.is_done ? "line-through" : undefined }}
				value={editedValues.title}
				onInput={(e) => handleInput("title", e)}
				onBlur={(e) => handleBlur("title", e)}
				onKeyDownProcessor={(e) => handleKeyDown(e, "title")}
				error={errors.title}
				isEditing={isEditing}
				area={area}
				isStriked={task.is_done}
			/>
			<TaskTimeEstimate
				value={editedValues.time_estimate}
				onInput={(e) => handleInput("time_estimate", e)}
				onBlur={(e) => handleBlur("time_estimate", e)}
				onKeyDownProcessor={(e) => handleKeyDown(e, "title")}
				error={errors.time_estimate}
				area={area}
			/>
			<TaskPriority
				value={editedValues.priority}
				onInput={(e) => handleInput("priority", e)}
				onBlur={(e) => handleBlur("priority", e)}
				onKeyDownProcessor={(e) => handleKeyDown(e, "title")}
				error={errors.priority}
				area={area}
			/>
			<TaskDueDate
				value={editedValues.due_date}
				onInput={(e) => handleInput("due_date", e)}
				onBlur={(e) => handleBlur("due_date", e)}
				onKeyDownProcessor={(e) => handleKeyDown(e, "title")}
				error={errors.due_date}
				area={area}
			/>
			<button
				data-testid="delete-task-button"
				className="task-delete"
				onClick={() => deleteData.mutateAsync({ item: task })}
			>
				⨯
			</button>
		</div>
	);
}

Task.propTypes = {
	task: TaskType.isRequired,
	isEditing: PropTypes.bool,
	isSelected: PropTypes.bool.isRequired,
	onSelect: PropTypes.func.isRequired,
	keypressInTaskHandler: PropTypes.func.isRequired,
};

export default React.memo(Task);
