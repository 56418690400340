import React, { useState, useCallback, useEffect, useMemo } from "react";
import * as query from "./TaskList.query";
import { compareIds } from "../../utils/transient_persistent_layer";
import * as areaQuery from "../area/AreaList.query";
import Task from "./Task";
import "./TaskList.css";
import { useTranslation } from "react-i18next";

export function TaskList() {
	const [editingTaskId] = useState(null);
	const [selectedTasks, setSelectedTasks] = useState({});
	const [selectedIdx, setSelectedIdx] = useState(null);
	const { data: areas = [], isLoading: isLoadingAreas } =
		areaQuery.useAreasIncludingTransient();

	const { t } = useTranslation();
	const {
		enabledIds: enabledAreas,
		selectedId: selectedAreaId,
		setSelectedId: setSelectedAreaId,
	} = areaQuery.useEnabledAreas();

	const {
		getData: { data: tasks = [], isLoading: isLoadingTasks, error: isError },
		createTransientData,
		updateArea,
	} = query.tasks();

	const [hideFinishedTasks, setHideFinishedTasks] = useState(() => {
		return window.hideFinishedTasks === undefined
			? true
			: window.hideFinishedTasks;
	});
	useEffect(() => {
		window.hideFinishedTasks = hideFinishedTasks;
	}, [hideFinishedTasks]);

	const visibleTasks = useMemo(() => {
		return tasks.filter((task) => {
			return (
				enabledAreas[task.area_id] &&
				!task.is_deleted &&
				(!hideFinishedTasks || !task.is_done)
			);
		});
	}, [tasks, enabledAreas, hideFinishedTasks]);

	const handleTaskSelection = (task) => {
		setSelectedTasks((prev) => {
			const newSelectedTasks = { ...prev };
			if (task.id in newSelectedTasks) {
				delete newSelectedTasks[task.id];
			} else {
				newSelectedTasks[task.id] = task;
			}
			return newSelectedTasks;
		});
	};

	// ... existing code ...

	const handleMoveTasksToArea = () => {
		updateArea.mutateAsync(
			{ newAreaId: selectedAreaId, tasks: Object.values(selectedTasks) },
			{
				onSuccess: () => {
					// Clear selection after successful mutation
				},
				onError: (error) => {
					console.error("Error moving tasks:", error);
					// Handle error (e.g., show error message to user)
				},
			},
		);

		// Optimistically update UI
		setSelectedTasks([]);
	};

	const selectUp = useCallback(() => {
		setSelectedIdx((prevIdx) => {
			if (prevIdx === null) {
				return visibleTasks.length - 1;
			} else if (prevIdx > 0) {
				return prevIdx - 1;
			}
			return prevIdx;
		});
	}, [visibleTasks]);

	const selectDown = useCallback(() => {
		setSelectedIdx((prevIdx) => {
			if (prevIdx === null) {
				return 0;
			} else if (prevIdx < visibleTasks.length - 1) {
				return prevIdx + 1;
			}
			return prevIdx;
		});
	}, [visibleTasks]);

	const selectNone = useCallback(() => {
		setSelectedIdx(null);
	}, []);

	const tryAddAnotherTask = useCallback(
		(task) => {
			// find idx in visibleTasks
			const idx = visibleTasks.findIndex((t) => {
				return compareIds(t.id, task.id);
			});

			// insert at bottom if current task is closer to bottom than not
			const insert_at_bottom = idx > (visibleTasks.length - 1) / 2;
			createTransientData.mutateAsync({
				insert_at_bottom: insert_at_bottom,
				area_id: selectedAreaId,
			});
		},
		[createTransientData, visibleTasks, selectedAreaId],
	);

	const keypressInTaskHandler = useCallback(
		(event, field, task) => {
			const actions = {
				Enter: tryAddAnotherTask,
			};
			actions[event.key]?.(task);
		},
		[tryAddAnotherTask],
	);

	useEffect(() => {
		const handleGlobalKeyDown = (event) => {
			const actions = {
				ArrowUp: selectUp,
				ArrowDown: selectDown,
				Escape: selectNone,
			};
			actions[event.key]?.();
		};

		document.addEventListener("keydown", handleGlobalKeyDown);
		return () => document.removeEventListener("keydown", handleGlobalKeyDown);
	}, [selectUp, selectDown, selectNone]);

	useEffect(() => {
		window.hideFinishedTasks = hideFinishedTasks;
	}, [hideFinishedTasks]);

	if (isLoadingTasks || isLoadingAreas) return <div>Loading tasks...</div>;
	if (isError) return <div>Error loading tasks</div>;

	return (
		<div className="task-list">
			<div className="hide-finished-tasks">
				<label>
					<input
						type="checkbox"
						data-testid="hide-finished-tasks-checkbox"
						checked={hideFinishedTasks}
						onChange={(e) => setHideFinishedTasks(e.target.checked)}
					/>
					Hide Finished Tasks
				</label>
			</div>

			<div className="move-tasks-controls">
				<select
					data-testid="move-area-select"
					value={selectedAreaId}
					onChange={(e) => {
						if (!e.target.value) {
							return;
						}
						setSelectedAreaId({
							newId: parseInt(e.target.value),
							isExclusive: true,
						});
					}}
				>
					<option value="">{t("Select Area")}</option>
					{areas.map((area) => (
						<option key={area.id} value={area.id}>
							{area.name === null ? t("defaultAreaName") : area.name}
						</option>
					))}
				</select>
				<button
					data-testid="move-area-button"
					onClick={handleMoveTasksToArea}
					disabled={selectedTasks.length === 0 || !selectedAreaId}
				>
					Move Selected Tasks to This Area
				</button>
			</div>

			<button
				data-testid="top-add-task-button"
				className="add-task-button"
				onClick={() =>
					createTransientData.mutateAsync({
						insert_at_bottom: false,
						area_id: selectedAreaId,
					})
				}
			>
				+
			</button>

			<div className="task-grid">
				<div className="task task-list-header task-row">
					<span className="task-select"></span>
					<span></span>
					<span className="task-title">Title</span>
					<span className="task-time-estimate">Time Estimate</span>
					<span className="task-priority">Priority</span>
					<span className="task-due-date">Due Date</span>
					<span></span>
				</div>
				{visibleTasks.map((task, index) => (
					<div
						key={task.transient.key}
						data-testid="task-row"
						className="task-row"
					>
						<input
							data-testid="move-area-checkbox"
							className="task-select"
							type="checkbox"
							checked={task.id in selectedTasks}
							onChange={() => handleTaskSelection(task)}
						/>
						<Task
							task={task}
							isEditing={compareIds(editingTaskId, task.id)}
							isSelected={index === selectedIdx}
							onSelect={() => setSelectedIdx(index)}
							keypressInTaskHandler={keypressInTaskHandler}
						/>
					</div>
				))}
			</div>

			<button
				data-testid="bottom-add-task-button"
				className="add-task-button"
				onClick={() =>
					createTransientData.mutateAsync({
						insert_at_bottom: true,
						area_id: selectedAreaId,
					})
				}
			>
				+
			</button>
		</div>
	);
}
