import PropTypes from "prop-types";

export const AreaType = PropTypes.shape({
	// required things
	id: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.arrayOf(
			PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		),
	]).isRequired,
	name: PropTypes.string.isRequired,

	// nullable things
	color: PropTypes.string,

	// TODO should probably add: enabled: PropTypes.bool.isRequired,
});
