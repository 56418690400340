import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FaWrench, FaPalette, FaTimes, FaTimesCircle } from "react-icons/fa";
import { CirclePicker } from "react-color";
import { useEnabledAreas, useAreasIncludingTransient } from "./AreaList.query";
import { AreaType } from "./Area.type";
import "./Area.css";

export default function Area({ area, isNew }) {
	const { deleteArea, saveArea, isTransient } = useAreasIncludingTransient();
	const [isEditing, setIsEditing] = useState(isNew);
	const [editedName, setEditedName] = useState(area.name);
	const [showDeleteButton, setShowDeleteButton] = useState(false);
	const [validationError, setError] = useState(false);
	const { t } = useTranslation();
	const nameRef = useRef(null);
	const [showColorPicker, setShowColorPicker] = useState(false);
	const colorPickerRef = useRef(null);

	const { enabledIds, setEnabledIds } = useEnabledAreas();

	useEffect(() => {
		if (isNew && nameRef.current) {
			nameRef.current.focus();
		}
	}, [isNew]);

	const validateName = (name) => {
		return name.trim().length >= 3;
	};

	const handleNameInput = (e) => {
		// TODO escape should exit without saving, cancelling edit mode and reverting any changes
		const value = e.target.innerText.replace(/\n/g, ""); // Remove newlines
		if (value != e.target.innerText) {
			e.target.innerText = editedName;
			if (validateName(e.target.innerText)) {
				///e.target.blur(); // enter probably means the user is done editing
				nameRef.current.blur(); // enter probably means the user is done editing
			}
			return; // reject enter-presses in every other sense
		}
		setEditedName(value);

		if (!validateName(value)) {
			setError(true);
			e.target.classList.add("flash-error");
			setTimeout(() => {
				e.target.classList.remove("flash-error");
			}, 300);
		} else {
			setError(false);
		}
	};

	const handleNameBlur = async (event) => {
		const trimmedName = editedName.trim();
		if (validateName(trimmedName)) {
			saveArea(area.id, { ...area, name: trimmedName });
			setError(false);
		} else {
			if (isTransient(area.id)) {
				// New area: remove the component
				deleteArea(area);
			} else {
				// Existing area: revert to original name
				setError(true);
				nameRef.current.innerText = area.name;
			}
		}

		if (
			event.relatedTarget &&
			(event.relatedTarget.closest(".no-blur-button") ||
				event.relatedTarget.closest(".no-blur-container") ||
				event.relatedTarget
					.closest(".no-blur-container")
					?.contains(event.relatedTarget))
		) {
			return; // prevent toggling edit mode
		}

		setIsEditing(false);
		setShowDeleteButton(false);
	};

	const handleColorChange = async (color) => {
		const newColor = color.hex;
		saveArea(area.id, { ...area, color: newColor });
		setShowColorPicker(false);
	};

	const toggleColorPicker = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setShowColorPicker(!showColorPicker);
	};

	const removeColor = async (event) => {
		event.preventDefault();
		event.stopPropagation();
		saveArea(area.id, { ...area, color: null });
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				colorPickerRef.current &&
				!colorPickerRef.current.contains(event.target)
			) {
				setShowColorPicker(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const toggleEdit = (event) => {
		event.preventDefault();
		event.stopPropagation();
		const newState = !isEditing;
		setIsEditing(newState);
		setShowDeleteButton(newState);
		if (newState) {
			setTimeout(() => nameRef.current.focus(), 0);

			const range = document.createRange();
			range.selectNodeContents(nameRef.current);
			const selection = window.getSelection();
			selection.removeAllRanges();
			selection.addRange(range);
		}
	};

	const handleDelete = (event) => {
		event.preventDefault();
		event.stopPropagation();

		deleteArea(area);
	};

	const handleAreaToggle = () => {
		setEnabledIds.mutateAsync({
			areaId: area.id,
			isEnabled: !enabledIds[area.id],
			isExclusive: false,
		});
	};

	return (
		<div className="area-list-task">
			<label>
				<input
					type="checkbox"
					checked={enabledIds[area.id] || false}
					onChange={handleAreaToggle}
				/>
				<span
					ref={nameRef}
					contentEditable={isEditing}
					onInput={handleNameInput}
					onBlur={handleNameBlur}
					suppressContentEditableWarning={true}
					className={validationError ? "error" : ""}
					style={{ whiteSpace: "nowrap", overflow: "hidden" }} // Prevent line breaks
					data-testid="area-name"
				>
					{area.name === null ? t("defaultAreaName") : area.name}
				</span>
			</label>
			{validationError && (
				<span className="error-message">{t("areaNameTooShort")}</span>
			)}
			{area.name !== null && ( // null name means default area
				<>
					<FaWrench
						onClick={toggleEdit}
						className="no-blur-button"
						tabIndex={0}
						data-testid="area-edit-button"
					/>
					{isEditing && (
						<>
							{area.color ? (
								<div
									className="color-icon-container no-blur-button"
									onClick={removeColor}
									tabIndex={0}
								>
									<FaPalette
										data-testid="area-remove-color-button"
										style={{ color: area.color }}
									/>
									<FaTimesCircle className="remove-color-icon" />
								</div>
							) : (
								<FaPalette
									data-testid="area-change-color-button"
									onClick={toggleColorPicker}
									className="no-blur-button"
									tabIndex={0}
								/>
							)}
							{showColorPicker && (
								<div
									className="no-blur-container"
									ref={colorPickerRef}
									style={{
										position: "absolute",
										zIndex: 2,
										background: "#fff",
										padding: "10px",
										borderRadius: "4px",
										boxShadow: "0 0 10px rgba(0,0,0,0.25)",
									}}
								>
									<CirclePicker
										color={area.color || "#000000"}
										onChange={handleColorChange}
										colors={[
											"#FFB3BA",
											"#BAFFC9",
											"#BAE1FF",
											"#FFFFBA",
											"#FFDFBA",
											"#E0BBE4",
											"#D4E2D4",
											"#FCE1E4",
											"#E8DFF5",
											"#C3E0E5",
											"#F0E0D6",
											"#D5E8D4",
											"#E6E6FA",
											"#FFF0F5",
											"#F0FFF0",
											"#F5F5DC",
											"#D6D6EA",
											"#F0E68C",
										]}
									/>
								</div>
							)}
							{showDeleteButton && (
								<FaTimes
									data-testid="area-delete-button"
									onClick={handleDelete}
									className="no-blur-button"
									tabIndex={0} // Make it focusable
								/>
							)}
						</>
					)}
				</>
			)}
		</div>
	);
}

Area.propTypes = {
	area: AreaType.isRequired,
	isNew: PropTypes.bool,
};
