import * as api from "../../utils/api";
import { useQuery, useQueryClient, useMutation } from "react-query";
import * as queryKeyFactory from "../../query_key_factory";
import { useCallback } from "react";
import {
	createTransientPersistentLayer,
	isTransient,
	compareIds,
	PersistedKeyIdentifier,
} from "../../utils/transient_persistent_layer";
import { KeyAssigner } from "../../utils/key_assigner";

window.areaKeyAssigner = window.areaKeyAssigner || new KeyAssigner();
window.areaPersistedKeyIdentifier =
	window.areaPersistedKeyIdentifier ||
	new PersistedKeyIdentifier(["id", "name", "color"]);
// Transient storage
window.transientAreas = window.transientAreas || null;

// Helper functions
const fetchAreas = api.fetchAreas;
const addArea = api.addArea;
const updateArea = (area) => {
	return api.updateArea(area.id, area);
};
const deleteArea = async ({ item, delete_state }) => {
	if (delete_state) {
		return await api.deleteArea(item.id);
	} else {
		throw Error("delete_state must be true");
	}
};

const loadTransientAreas = () => window.transientAreas || [];
const saveTransientAreas = (areas) => {
	window.transientAreas = areas;
};

const areaComparator = (a, b) => {
	return compareIds(a.id, b.id);
};

const { /*persistentLayer, transientLayer,*/ combinedLayer } =
	createTransientPersistentLayer({
		persistentQueryKey: queryKeyFactory.areas.persistent(),
		transientQueryKey: queryKeyFactory.areas.transient(),
		fetchItems: fetchAreas,
		createItem: addArea,
		updateItem: updateArea,
		deleteItem: deleteArea,
		setItemProperty: ({ item, property, value }) =>
			updateArea({ areaId: item.id, updates: { [property]: value } }),
		itemComparator: areaComparator,
		loadTransientItems: loadTransientAreas,
		saveTransientItems: saveTransientAreas,
		keyAssigner: window.areaKeyAssigner,
		persistedKeyIdentifier: window.areaPersistedKeyIdentifier,
	});

export function useAreas() {
	return combinedLayer().getData;
}

export function useAddArea() {
	return combinedLayer().createData;
}

export function useUpdateArea() {
	return combinedLayer().updateData;
}

export function useDeleteArea() {
	return combinedLayer().deleteData;
}

export function useSetAreaProperty() {
	return combinedLayer().setProperty;
}

export function useEnabledAreas() {
	const queryClient = useQueryClient();
	const enabledIds = useQuery(queryKeyFactory.areas.enabled(), () => {
		return window.enabledAreaIds || { 1: true };
	});

	const setEnabledIds = useMutation(
		({ areaId, isEnabled, isExclusive }) => {
			const currentEnabledAreas = window.enabledAreaIds || { 1: true };
			const updatedEnabledAreas = {
				...(isExclusive ? {} : currentEnabledAreas),
				[areaId]: isEnabled,
			};
			window.enabledAreaIds = updatedEnabledAreas;
			return updatedEnabledAreas;
		},
		{
			onMutate: ({ areaId, isEnabled, isExclusive }) => {
				queryClient.setQueryData(queryKeyFactory.areas.enabled(), (oldData) => {
					return {
						...(isExclusive ? {} : oldData),
						[areaId]: isEnabled,
					};
				});
			},
		},
	);

	const { data: selectedId } = useQuery(
		queryKeyFactory.areas.selectedId(),
		() => {
			return window.selectedAreaId || 1;
		},
	);

	const _setSelectedAreaId = useMutation(
		({ newId }) => {
			window.selectedAreaId = newId;
			return newId;
		},
		{
			onMutate: ({ newId }) => {
				queryClient.setQueryData(queryKeyFactory.areas.selectedId(), newId);
			},
		},
	);

	const setSelectedId = useCallback(
		({ newId, isExclusive }) => {
			_setSelectedAreaId.mutateAsync({ newId });
			setEnabledIds.mutateAsync({
				areaId: newId,
				isEnabled: true,
				isExclusive: isExclusive,
			});
		},
		[_setSelectedAreaId, setEnabledIds],
	);

	return {
		enabledIds: enabledIds.data,
		setEnabledIds,
		selectedId,
		setSelectedId,
	};
}

// Simplified version of areasIncludingTransient
export function useAreasIncludingTransient() {
	const { data: areas, isLoading, error, isUnauthorized } = useAreas();
	const addArea = useAddArea();
	const updateArea = useUpdateArea();
	const deleteArea = useDeleteArea();

	const addTransientArea = useCallback(
		(name) => {
			const newArea = {
				name,
				color: "#000000",
				isNew: true,
				id: ["new", Date.now()],
			};
			addArea.mutateAsync(newArea);
		},
		[addArea],
	);

	const saveArea = useCallback(
		(areaId, updatedArea) => {
			/*
			if (isTransient(areaId)) {
				addArea.mutate(updatedArea, {
					onSuccess: () => {
						deleteArea.mutate({ id: areaId });
					},
				});
			} else {
				updateArea.mutate({ item: { ...updatedArea, id: areaId } });
			}
			*/
			updateArea.mutateAsync({ item: { ...updatedArea, id: areaId } });
		},
		[addArea, updateArea, deleteArea],
	);

	return {
		data: areas || [],
		isLoading,
		error,
		addTransientArea,
		deleteArea: (area) => {
			return deleteArea.mutateAsync({ item: area });
		},
		saveArea,
		isTransient,
		isUnauthorized,
	};
}
