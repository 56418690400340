import React from "react";
import PropTypes from "prop-types";
import TaskField from "./TaskField";
import { AreaType } from "../area/Area.type";
export default function TaskPriority({
	value,
	onInput,
	onBlur,
	error,
	area,
	onKeyDownProcessor,
}) {
	return (
		<TaskField
			className="task-priority"
			value={value}
			onInput={onInput}
			onBlur={onBlur}
			error={error}
			area={area}
			testId="task-priority"
			onKeyDownProcessor={onKeyDownProcessor}
		/>
	);
}

TaskPriority.propTypes = {
	value: PropTypes.number,
	onInput: PropTypes.func.isRequired,
	onBlur: PropTypes.func.isRequired,
	error: PropTypes.bool,
	area: AreaType.isRequired,
	onKeyDownProcessor: PropTypes.func,
};
