import { React, useCallback } from "react";
import { useQueryClient } from "react-query";
import { GoogleLogin /*, useGoogleOneTapLogin*/ } from "@react-oauth/google";
//import { GoogleLoginButton } from "react-social-login-buttons";
//import { useGoogleLogin } from "@react-oauth/google";
import { login, logout } from "../utils/api";
import * as areaQuery from "./area/AreaList.query";
import { invalidateAllPersistent } from "../utils/transient_persistent_layer";

export const GoogleLoginComponent = () => {
	// areas provides a useful proxy for whether the user is logged in or not
	const queryClient = useQueryClient();
	const { isLoading, isUnauthorized } = areaQuery.useAreasIncludingTransient();

	const handleLoginSuccess = useCallback(
		(credentialResponse) => {
			console.log(credentialResponse);
			login("google", [
				credentialResponse.credential,
				credentialResponse.clientId,
			])
				.then((response) => {
					console.log("Login response:", response.data);
					invalidateAllPersistent(queryClient);
				})
				.catch((error) => {
					console.error("Login error:", error);
					invalidateAllPersistent(queryClient);
				});
		},
		[queryClient],
	);

	const handleLoginError = useCallback(() => {
		console.log("Login Failed");
		logout().then(() => {
			invalidateAllPersistent(queryClient);
		});
	}, [queryClient]);

	const onLogout = useCallback(() => {
		logout().then(() => {
			invalidateAllPersistent(queryClient);
		});
	}, [queryClient]);

	/*
	const oneTapLogin = useGoogleOneTapLogin({
		onSuccess: handleLoginSuccess,
		onError: handleLoginError,
	});

	const login2 = useGoogleLogin({
		onSuccess: (codeResponse) => console.log(codeResponse),
		flow: "auth-code",
	});
	*/

	return (
		<div>
			{isLoading ? (
				<div>Loading...</div>
			) : isUnauthorized ? (
				<GoogleLogin
					onSuccess={handleLoginSuccess}
					onError={handleLoginError}
					useOneTap
					locale="en"
				/>
			) : (
				<button onClick={onLogout}>Logout</button>
			)}
			{/*
			<GoogleLogin onSuccess={handleLoginSuccess} onError={handleLoginError} />
			<GoogleLoginButton onClick={oneTapLogin} />
			<GoogleLoginButton onClick={login2} />
			*/}
		</div>
	);
};
