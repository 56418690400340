export class KeyAssigner {
	constructor() {
		this.nextKey = 1;
		this.keys = {};
	}
	next() {
		return this.nextKey++;
	}
	getKeyFor(id) {
		if (!(id in this.keys)) {
			this.keys[id] = this.next();
		}
		return this.keys[id];
	}
	shareKey(existingId, newId) {
		if (!(existingId in this.keys)) {
			this.getKeyFor(existingId);
			console.error("shareKey called with undefined key " + existingId);
		}
		if (!(newId in this.keys) || this.keys[newId] !== this.keys[existingId]) {
			this.keys[newId] = this.keys[existingId];
		}
	}
}
