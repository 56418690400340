export const tasks = {
	all: () => ["tasks"],
	persistent: () => ["tasks", "persistent"],
	transient: () => ["tasks", "transient"],
	/*
	areas: (areaIds) => {
		if (
			!Array.isArray(areaIds) ||
			!areaIds.every((id) => typeof id === "number")
		) {
			throw new Error("areaIds must be a list containing only numbers");
		}
		return ["tasks", "areas", areaIds.sort()];
	},
	area: (areaId) => tasks["areas"]([areaId]),
	task: (taskId) => ["tasks", [taskId]],
    */
};

export const areas = {
	all: () => ["areas"],
	persistent: () => ["areas", "persistent"],
	transient: () => ["areas", "transient"],
	enabled: () => ["areas-enabled"],
	selectedId: () => ["areas-selectedId"],
	//area: (areaId) => ["areas", [areaId]],
};
