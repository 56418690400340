import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as query from "./AreaList.query";
import Area from "./Area";
import { FaPlus, FaStar } from "react-icons/fa";
import "./AreaList.css";

export function AreaList() {
	const [isExpanded, setIsExpanded] = useState(false);
	const { t } = useTranslation();
	const {
		data: areas = [],
		isLoading,
		error,
		addTransientArea,
	} = query.useAreasIncludingTransient();
	const { selectedId: selectedAreaId, setSelectedId } = query.useEnabledAreas();

	const toggleExpand = () => {
		setIsExpanded(!isExpanded);
	};

	const handleAddTransientArea = (event) => {
		event.stopPropagation();
		addTransientArea("");
	};

	const getSelectedAreaName = () => {
		const selectedArea = areas?.find((area) => area.id === selectedAreaId);
		return selectedArea
			? selectedArea.name === null
				? t("defaultAreaName")
				: selectedArea.name
			: "";
	};

	if (isLoading) return <div>Loading...</div>;
	if (error) return <div>Error loading areas: {error.message}</div>;

	return (
		<div className="area-list">
			<div
				className="area-list-header"
				onClick={toggleExpand}
				data-testid="area-list-toggle-button"
			>
				{t("areas")} - {getSelectedAreaName()} {isExpanded ? "▲" : "▼"}
				{isExpanded && (
					<FaPlus
						onClick={handleAddTransientArea}
						className="add-area-button"
						data-testid="area-add-button"
					/>
				)}
			</div>
			{isExpanded && (
				<ul className="area-list-tasks">
					{areas.map((area) => (
						<li
							key={area.id}
							className={`area-item ${area.id === selectedAreaId ? "selected" : ""}`}
						>
							<FaStar
								className={`star-icon ${area.id === selectedAreaId ? "selected" : ""}`}
								onClick={() =>
									setSelectedId({ newId: area.id, isExclusive: true })
								}
							/>
							<Area area={area} isNew={area.isNew ? true : false} />
						</li>
					))}
				</ul>
			)}
		</div>
	);
}
