import React from "react";
import PropTypes from "prop-types";
import TaskField from "./TaskField";
import { AreaType } from "../area/Area.type";

export default function TaskTitle({
	titleCallback,
	value,
	onInput,
	onBlur,
	error,
	area,
	isStriked,
	onKeyDownProcessor,
}) {
	return (
		<TaskField
			testId="task-title"
			className="task-title"
			value={value}
			onInput={onInput}
			onBlur={onBlur}
			error={error}
			refCallback={titleCallback}
			area={area}
			isStriked={isStriked}
			onKeyDownProcessor={onKeyDownProcessor}
		/>
	);
}

TaskTitle.propTypes = {
	titleCallback: PropTypes.func,
	value: PropTypes.string,
	onInput: PropTypes.func.isRequired,
	onBlur: PropTypes.func.isRequired,
	error: PropTypes.bool,
	area: AreaType.isRequired,
	isStriked: PropTypes.bool,
	onKeyDownProcessor: PropTypes.func,
};
