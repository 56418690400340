import React from "react";
import PropTypes from "prop-types";
import TaskField from "./TaskField";
import { AreaType } from "../area/Area.type";
export default function TaskDueDate({
	value,
	onInput,
	onBlur,
	error,
	area,
	onKeyDownProcessor,
}) {
	return (
		<TaskField
			className="task-due-date"
			value={value}
			onInput={onInput}
			onBlur={onBlur}
			error={error}
			area={area}
			testId="task-due-date"
			onKeyDownProcessor={onKeyDownProcessor}
		/>
	);
}

TaskDueDate.propTypes = {
	value: PropTypes.string,
	onInput: PropTypes.func.isRequired,
	onBlur: PropTypes.func.isRequired,
	error: PropTypes.bool,
	area: AreaType.isRequired,
	onKeyDownProcessor: PropTypes.func,
};
