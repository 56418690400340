import axios from "axios";

const backendURL = process.env.REACT_APP_API_URL;
const api = axios.create({
	baseURL: backendURL,
	withCredentials: true, // This is the key setting
});

// New decorator function
const handleAxiosResponse = (axiosPromise) => {
	return axiosPromise.then((response) => response.data);
};

const createTask = (task) => {
	// remove id from editedValues
	// eslint-disable-next-line no-unused-vars
	const { id, ...taskData } = task;

	if (taskData.title === "") {
		throw new Error("Title is required");
	}

	// creating new task
	return handleAxiosResponse(api.post(`/tasks`, taskData));
};
const saveTask = (task) => {
	// remove id from editedValues
	const { id, ...taskData } = task;

	if (taskData.title === "") {
		throw new Error("Title is required");
	}

	// updating old task
	return handleAxiosResponse(api.patch(`/tasks/${id}`, taskData));
};

const toggleTaskDone = (id, isDone) => {
	return setTaskDone(id, !isDone);
};
const setTaskDone = (id, isDone) => {
	return handleAxiosResponse(api.patch(`/tasks/${id}/done/${isDone}`));
};

const toggleTaskDelete = (id, isDeleted) => {
	return setTaskDelete(id, !isDeleted);
};
const setTaskDelete = (id, isDeleted) => {
	return handleAxiosResponse(api.patch(`/tasks/${id}/delete/${isDeleted}`));
};

// New function to fetch tasks
const fetchTasks = () => {
	return handleAxiosResponse(api.get("/tasks")).then((data) => {
		return data.map((task) => ({
			...task,
			title: task.title || task.description,
			description: task.title ? task.description : "",
		}));
	});
};

// New function to fetch areas
const fetchAreas = () => {
	return handleAxiosResponse(api.get(`/areas`));
};

const updateArea = (areaId, updates) => {
	return handleAxiosResponse(api.patch(`/areas/${areaId}`, updates));
};

const deleteArea = (areaId) => {
	return handleAxiosResponse(api.delete(`/areas/${areaId}`));
};

const addArea = (newArea) => {
	return handleAxiosResponse(api.post(`/areas`, newArea));
};

const moveTasksToArea = (areaId, taskIds) => {
	return handleAxiosResponse(api.post(`/areas/${areaId}/move_tasks`, taskIds));
};

const login = (provider, token) => {
	// response is { "token": "..." }
	return handleAxiosResponse(api.post("/account/login", { provider, token }));
};

const logout = () => {
	return handleAxiosResponse(api.post("/account/logout"));
};

export {
	createTask,
	saveTask,
	toggleTaskDone,
	setTaskDone,
	toggleTaskDelete,
	setTaskDelete,
	fetchTasks,
	fetchAreas,
	updateArea,
	deleteArea,
	addArea,
	moveTasksToArea,
	login,
	logout,
};
