export const validate = (field, value) => {
	let validatedValue = value;
	let isValid = true;

	if (
		field !== "title" &&
		(value === null || value === undefined || value === "")
	) {
		isValid = true;
		validatedValue = null;
	} else if (field === "title") {
		validatedValue = value.trim();
		isValid = validatedValue.length >= 3;
	} else {
		if (field === "time_estimate") {
			if (value.includes(".")) {
				validatedValue = parseFloat(value);
			} else {
				validatedValue = parseInt(value);
			}

			isValid =
				!isNaN(validatedValue) &&
				validatedValue.toString() === value.trim() &&
				validatedValue >= 0;
		} else if (field === "priority") {
			validatedValue = parseInt(value, 10);
			isValid =
				!isNaN(validatedValue) &&
				validatedValue.toString() === value.trim() &&
				validatedValue >= -10 &&
				validatedValue <= 10;
		} else if (field === "due_date") {
			const dateRegex = /^(\d{4}-\d{2}-\d{2})( \d{2}:\d{2})?$/;
			const match = value.match(dateRegex);
			if (match) {
				const [, datePart, timePart] = match;
				const dateTimeString = `${datePart}${timePart || " 00:00"}`;
				const localDate = new Date(dateTimeString);
				validatedValue = new Date(
					localDate.getTime() - localDate.getTimezoneOffset() * 60000,
				);
				isValid = !isNaN(validatedValue.getTime());
				if (isValid) {
					validatedValue = validatedValue.getTime();
				}
			} else {
				isValid = false;
			}
		} else {
			validatedValue = value.trim();
			isValid =
				!validatedValue ||
				(typeof validatedValue === "string" && validatedValue.trim() === "");
		}
	}

	return { isValid, validatedValue };
};
