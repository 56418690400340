import { useMemo } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import * as queryKeyFactory from "../query_key_factory";

/**
 * PersistedKeyIdentifier class
 *
 * This class is responsible for managing and identifying persisted keys in an item.
 * It helps determine which keys should be considered when comparing or updating items.
 */

export const invalidateAllPersistent = (queryClient) => {
	const allKeys = [
		queryKeyFactory.tasks.persistent(),
		queryKeyFactory.areas.persistent(),
	];
	allKeys.forEach((key) => {
		console.log("invalidating all persistent", key);
		queryClient.invalidateQueries(
			{
				queryKey: key,
				refetchType: "all",
				exact: false,
			},
			{
				cancelRefetch: true,
			},
		);
		/*
		queryClient.refetchQueries(
			{
				queryKey: key,
				exact: false,
			},
			{
				cancelRefetch: true,
			},
		);
		*/
	});
};

export class PersistedKeyIdentifier {
	/**
	 * Constructor for PersistedKeyIdentifier
	 * @param {string[]} persistedKeys - Initial array of persisted keys
	 */
	constructor(persistedKeys = []) {
		this.persistedKeys = persistedKeys;
	}

	/**
	 * Checks if a given key is a persisted key
	 * @param {string} key - The key to check
	 * @returns {boolean} True if the key is persisted, false otherwise
	 */
	isPersistedKey(key) {
		return (
			this.persistedKeys.length == 0 ||
			// always exclude 'id' since it cannot be updated
			(key != "id" && this.persistedKeys.includes(key))
		);
	}

	/**
	 * Adds new persisted keys from an item
	 * @param {Object} item - The item to extract keys from
	 *
	 * This method will only add keys if the persistedKeys array is empty.
	 * It's used to initialize the persisted keys from the first item encountered.
	 */
	addPersistedKeys(item) {
		if (this.persistedKeys.length == 0) {
			for (const key in item) {
				if (!this.persistedKeys.includes(key)) {
					this.persistedKeys.push(key);
				}
			}
		}
	}
}

export const compareIds = (id1, id2) => {
	if (Array.isArray(id1) && Array.isArray(id2)) {
		// compare the array
		if (id1.length !== id2.length) {
			return false;
		}
		for (let i = 0; i < id1.length; i++) {
			if (id1[i] !== id2[i]) {
				return false;
			}
		}
		return true;
	}
	return id1 === id2;
};

export const isTransient = (id) => {
	if (typeof id === "string" || typeof id === "number") {
		return false;
	}
	if (typeof id === "object") {
		if (id.id) {
			return isTransient(id.id);
		}
	}
	return Array.isArray(id) && id[0] === "new";
};

export const itemsIdentical = (i1, i2, persistedKeyIdentifier) => {
	const compareObjects = (obj1, obj2) => {
		const keys1 = Object.keys(obj1).filter((key) =>
			persistedKeyIdentifier.isPersistedKey(key),
		);
		const keys2 = Object.keys(obj2).filter((key) =>
			persistedKeyIdentifier.isPersistedKey(key),
		);

		if (keys1.length !== keys2.length) {
			return false;
		}

		for (const key of keys1) {
			if (!keys2.includes(key)) {
				return false;
			}

			if (obj1[key] === obj2[key]) {
				// return true;
			} else if (
				typeof obj1[key] === "object" &&
				typeof obj2[key] === "object"
			) {
				if (!compareObjects(obj1[key], obj2[key])) {
					return false;
				}
			} else if (obj1[key] !== obj2[key]) {
				return false;
			}
		}

		return true;
	};

	return compareObjects(i1, i2);
};

const anotherUpdateCheckCallback = (
	persistent,
	transient,
	sentItem,
	queryClient,
	loadTransientItems,
	keyAssigner,
	persistedKeyIdentifier,
) => {
	return async (createdItem) => {
		if (createdItem == null) {
			throw new Error("createdItem is null or undefined");
		}

		// is the transient item unchanged?
		let transientItem = loadTransientItems().find((t) =>
			compareIds(t.id, sentItem.id),
		);

		if (!transientItem) {
			// we're done here
			return;
		}

		// map the transient item key to the created item
		keyAssigner.shareKey(transientItem.id, createdItem.id);

		// check if the id of the transient item needs to be updated
		if (isTransient(transientItem.id)) {
			// remove transient-id version, readd with proper id
			await transient.deleteData.mutateAsync({ item: transientItem });
			await transient.updateData.mutateAsync({
				item: {
					...transientItem,
					id: createdItem.id,
					transient: {
						...transientItem.transient,
						is_create_pending: undefined,
					},
				},
			});

			// get the transient item with the new id
			transientItem = loadTransientItems().find((t) =>
				compareIds(t.id, createdItem.id),
			);
			if (!transientItem || isTransient(transientItem.id)) {
				// this shouldn't happen but check to be safe
				throw new Error(
					"transientItem not found or still transient " + transientItem,
					transientItem,
				);
			}
		}

		{
			// check if another item save is needed
			const itemRequiresUpdate = !itemsIdentical(
				transientItem,
				createdItem,
				persistedKeyIdentifier,
			);
			const updateLoopDetected = itemsIdentical(
				transientItem,
				sentItem,
				persistedKeyIdentifier,
			);

			if (itemRequiresUpdate) {
				if (updateLoopDetected) {
					throw Error("updateLoopDetected");
				}

				// TODO break out this onSuccess into a reusable function and use it for all creates/updates in persistent from combined
				persistent.updateData.mutateAsync(
					{
						item: { ...transientItem, id: createdItem.id },
					},
					{
						onSuccess: (response) => {
							anotherUpdateCheckCallback(
								persistent,
								transient,
								transientItem,
								queryClient,
								loadTransientItems,
								keyAssigner,
								persistedKeyIdentifier,
							)(response);
						},
					},
				);
				// actually lets keep the old transient items, they contain useful information such as positioning overrides
				// TODO schedule removal of transient things at some point??
				/*
            } else {
                // remove the now-unneeded transient item
                localStorage.setItem(
                    localStorageKey,
                    JSON.stringify(
                        loadTransientItems().filter(
                            (t) => !compareIds(t.id, transientItem.id),
                        ),
                    ),
                );
                queryClient.invalidateQueries(queryKeyFactory.items.transient());
                */
			}
		}
	};
};

export const createTransientPersistentLayer = ({
	persistentQueryKey,
	transientQueryKey,
	fetchItems,
	createItem,
	updateItem,
	deleteItem,
	setItemProperty,
	itemComparator,
	loadTransientItems,
	saveTransientItems,
	keyAssigner,
	persistedKeyIdentifier,
}) => {
	const persistentLayer = () => {
		const filter = {
			queryKey: persistentQueryKey,
			exact: false,
		};
		const queryClient = useQueryClient();

		const getData = useQuery(filter.queryKey, fetchItems, {
			select: (data) => {
				data.forEach((datum) => {
					persistedKeyIdentifier.addPersistedKeys(datum);
				});

				if (data.includes(undefined)) {
					throw new Error("Data contains undefined values", data);
				}

				return data.map((item) => ({
					...item,
					transient: {
						...item.transient,
						key: keyAssigner.getKeyFor(item.id),
					},
				}));
			},
			retry: (failureCount, error) => {
				// Stop retrying if the error is unauthorized (status 401)
				if (error?.status === 401) {
					return false;
				}
				// Otherwise, retry up to 3 times (default behavior)
				return failureCount < 3;
			},
		});

		const createData = useMutation(
			({ item }) => {
				const response = createItem(item);
				return response;
			},
			{
				onSuccess: (response) => {
					if (response === undefined || response === null) {
						throw new Error("Item cannot be undefined or null");
					}

					queryClient.setQueriesData(filter, (oldData) => [
						...oldData,
						response,
					]);
					return response;
				},
				onError: () => {
					queryClient.invalidateQueries(filter);
				},
			},
		);

		const updateData = useMutation(
			({ item }) => {
				// Check if the item is already in the persistent store
				const cachedItem = queryClient
					.getQueryData(filter.queryKey)
					?.find((oldItem) => compareIds(oldItem.id, item.id));
				if (cachedItem?.transient?.skipPersist) {
					// skip the next immediate persist, it's already updated
					return item;
				}

				return updateItem(item);
			},
			{
				onMutate: async ({ item }) => {
					// Check if the item is already in the persistent store
					let skipPersist = false;
					const cachedItem = queryClient
						.getQueryData(filter.queryKey)
						?.find((oldItem) => compareIds(oldItem.id, item.id));
					if (
						cachedItem &&
						itemsIdentical(cachedItem, item, persistedKeyIdentifier)
					) {
						skipPersist = true;
					}

					// Proceed with optimistic update
					queryClient.setQueriesData(filter, (oldData) => {
						return oldData.map((oldItem) => {
							if (compareIds(oldItem.id, item.id)) {
								return {
									...oldItem,
									...item,
									transient: {
										...oldItem.transient,
										...item.transient,
										// skip the next immediate persist, it's already updated
										skipPersist: skipPersist,
									},
								};
							}
							return oldItem;
						});
					});
					return item;
				},
				onError: () => {
					// Handle real errors
					queryClient.invalidateQueries(filter);
				},
			},
		);

		const deleteData = useMutation(deleteItem, {
			onMutate: ({ item, delete_state }) => {
				queryClient.setQueriesData(filter, (oldData) => {
					return oldData.map((oldItem) =>
						compareIds(oldItem.id, item.id)
							? { ...oldItem, is_deleted: delete_state }
							: oldItem,
					);
				});
			},
			onError: ({ item, delete_state }) => {
				// mutate back into existence
				queryClient.setQueriesData(filter, (oldData) =>
					oldData.map((oldItem) =>
						compareIds(oldItem.id, item.id)
							? { ...oldItem, is_deleted: !delete_state }
							: oldItem,
					),
				);
				// Refetch items
				queryClient.invalidateQueries(filter);
			},
		});

		const setProperty = useMutation(setItemProperty, {
			onMutate: ({ item, property, value }) => {
				queryClient.setQueriesData(filter, (oldData) =>
					oldData.map((oldItem) =>
						compareIds(oldItem.id, item.id)
							? { ...oldItem, [property]: value }
							: oldItem,
					),
				);
			},
			onError: () => {
				queryClient.invalidateQueries(filter);
			},
		});

		return {
			queryClient,
			filter,
			getData,
			createData,
			updateData,
			deleteData,
			setProperty,
		};
	};

	const transientLayer = () => {
		const filter = {
			queryKey: transientQueryKey,
			exact: false,
		};
		const queryClient = useQueryClient();

		const getData = useQuery(
			filter.queryKey,
			() => {
				const result = loadTransientItems();
				return result;
			},
			{
				select: (data) => {
					return data.map((item) => ({
						...item,
						transient: {
							...item.transient,
							key: keyAssigner.getKeyFor(item.id),
						},
					}));
				},
			},
		);

		const createData = useMutation(
			(newItem) => {
				const items = loadTransientItems();
				items.push(newItem);
				saveTransientItems(items);
				queryClient.setQueriesData(filter, loadTransientItems());
				return newItem;
			},
			{
				onError: () => {
					queryClient.invalidateQueries(filter);
				},
			},
		);

		const updateData = useMutation(
			({ item }) => {
				if (item === undefined || item === null) {
					throw new Error("Item cannot be undefined or null");
				}

				const items = loadTransientItems();
				let found = false;
				const updatedItems = items.map((i) => {
					if (compareIds(i.id, item.id)) {
						found = true;
						return { ...i, ...item };
					}
					return i;
				});
				// add if not found, may be a pending create or update
				if (!found) {
					updatedItems.push(item);
				}
				saveTransientItems(updatedItems);
				queryClient.setQueriesData(filter, loadTransientItems());
				return item;
			},
			{
				onError: () => {
					queryClient.invalidateQueries(filter);
				},
			},
		);

		const deleteData = useMutation(
			({ item }) => {
				const items = loadTransientItems();
				const updatedItems = items.filter((i) => !compareIds(i.id, item.id));
				saveTransientItems(updatedItems);
				queryClient.setQueriesData(filter, loadTransientItems());
				return item;
			},
			{
				onError: () => {
					queryClient.invalidateQueries(filter);
				},
			},
		);

		return { getData, createData, updateData, deleteData };
	};

	const combinedLayer = () => {
		const persistent = persistentLayer();
		const transient = transientLayer();
		const queryClient = useQueryClient();

		const getData = useMemo(() => {
			const persistentData = persistent.getData.data || [];
			const transientData = transient.getData.data || [];

			// assert that none of these contain undefined
			if (persistentData.includes(undefined)) {
				throw new Error(
					"persistentData contains undefined values",
					persistentData,
				);
			}
			if (transientData.includes(undefined)) {
				throw new Error(
					"transientData contains undefined values",
					transientData,
				);
			}

			// TODO get rid of unneeded items from transient once they are persisted, but remember to transfer over transient data
			const merged = [...persistentData, ...transientData].reduce(
				(acc, item) => {
					acc[item.id] = item;
					return acc;
				},
				{},
			);
			const result = Object.values(merged)
				.sort(itemComparator)
				.filter((item) => !item.is_deleted);
			return {
				data: result,
				isLoading: persistent.getData.isLoading || transient.getData.isLoading,
				isError: persistent.getData.isError || transient.getData.isError,
				error: persistent.getData.error || transient.getData.error,
				isUnauthorized: persistent.getData.error?.status === 401,
			};
		}, [persistent.getData, transient.getData]);

		const createData = transient.createData;

		const updateData = useMutation(
			async ({ item, skipPersist }) => {
				if (isTransient(item.id)) {
					if (skipPersist || item.transient?.isCreatePending) {
						return transient.updateData.mutate({ item });
					} else {
						transient.updateData.mutate({
							item: {
								...item,
								transient: { ...item.transient, isCreatePending: true },
							},
						});
						return persistent.createData.mutateAsync(
							{ item },
							{
								// after update, another update may be needed
								onSuccess: (response) => {
									anotherUpdateCheckCallback(
										persistent,
										transient,
										item,
										queryClient,
										loadTransientItems,
										keyAssigner,
										persistedKeyIdentifier,
									)(response);
								},
							},
						);
					}
				} else {
					// trigger an update, but also add it to the transient store temporarily
					transient.updateData.mutate({ item });
					return persistent.updateData.mutateAsync(
						{ item },
						{
							// after update, another update may be needed
							onSuccess: (response) => {
								anotherUpdateCheckCallback(
									persistent,
									transient,
									item,
									queryClient,
									loadTransientItems,
									keyAssigner,
									persistedKeyIdentifier,
								)(response);
							},
						},
					);
				}
			},
			{
				onError: () => {
					queryClient.invalidateQueries([
						...persistentQueryKey,
						...transientQueryKey,
					]);
				},
			},
		);

		const deleteData = useMutation(
			async ({ item }) => {
				if (isTransient(item.id)) {
					return transient.deleteData.mutate({ item });
				} else {
					persistent.deleteData.mutate({ item, delete_state: true });
					return transient.deleteData.mutate({ item, delete_state: true });
				}
			},
			{
				onError: () => {
					queryClient.invalidateQueries([
						...persistentQueryKey,
						...transientQueryKey,
					]);
				},
			},
		);

		const setProperty = useMutation(
			async ({ item, property, value }) => {
				if (isTransient(item.id)) {
					// just do the transient update
					return transient.updateData.mutate({
						item: { ...item, [property]: value },
					});
				} else {
					// update both transient and persistent
					transient.updateData.mutate({
						item: { ...item, [property]: value },
					});
					return persistent.setProperty.mutate({ item, property, value });
				}
			},
			{
				onError: () => {
					queryClient.invalidateQueries([
						...persistentQueryKey,
						...transientQueryKey,
					]);
				},
			},
		);

		return {
			getData,
			createData,
			updateData,
			deleteData,
			setProperty,
			persistent,
			transient,
		};
	};

	return { persistentLayer, transientLayer, combinedLayer };
};
